.uploadRow {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imagePreviewModal .previewImg {
  width: 100%;
}

.uploadRequirementList {
  width: 96%;
  margin: 0 auto;
  text-align: left;
  display: flex;
  flex-direction: row;
  position: relative;
}

.uploadRequirementList > .point {
  position: relative;
  font-size: 17px;
  font-weight: 700;
  margin-right: 10px;
  /* margin-bottom: 0.5rem; */
}

.uploadRequirementList > .text {
  position: relative;
  font-size: 16px;
  margin: auto 0;
}

.uploadedPdfsDisplay {
  position: relative;
  justify-content: center;
  margin: 10px auto;
}

.pdfNameHolder {
  position: relative;
  width: max-content;
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}

.pdfName {
  position: relative;
  width: max-content;
  border: 1px solid #00b4e4;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-right: 0px solid transparent;
  padding: 0 10px 3px 12px;
}

.pdfRemoveButton {
  position: relative;
  background-color: #00b4e4;
  border: 1px solid #00b4e4;
  color: white;
  font-weight: 700;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  width: 30px;
  font-size: 12px;
  padding: 0px 6px 2px 0px;
  text-align: center;
  transition: background-color 0.5s, border 0.5s;
}

.pdfRemoveButton:hover {
  background-color: #43d7ff;
  border: 1px solid #43d7ff;
}
